import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../assets/riseup-logo-light-1.svg';

const Banner: React.FC = () => (
  <nav className="bg-riseup-blue">
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between h-16 items-center">
        <div className="flex">
          <div className="flex-shrink-0 flex items-center">
            <Link to="/">
              <img src={logo} className="w-32" alt="RiseUP Strategies" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  </nav>
);

export default Banner;
