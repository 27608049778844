import './tailwind.css';

import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import useScrollToTop from './hooks/useScrollToTop';
import useTracker from './hooks/useTracker';
import Intro from './pages/Intro';
import Questions from './pages/Questions';
import Results from './pages/Results';

function App(): React.ReactElement {
  useScrollToTop();
  useTracker();

  const { t } = useTranslation();

  return (
    <HelmetProvider>
      <Helmet>
        <title>{t('doc_title')}</title>
        <meta name="description" content={t('intro_sub')} />
      </Helmet>

      <main className="min-h-screen bg-gray-300">
        <Switch>
          <Route path="/results" component={Results} />
          <Route path="/questions" component={Questions} />
          <Route path="/" component={Intro} />
        </Switch>
      </main>
    </HelmetProvider>
  );
}

export default App;
