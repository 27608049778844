import React from 'react';
import { useTranslation } from 'react-i18next';

import ToggleButton from './ToggleButton';

const LanguageToggle: React.FC = () => {
  const { i18n } = useTranslation();

  const setLang = (l: string): void => {
    i18n.changeLanguage(l);
  };

  return (
    <div className="rounded-lg bg-indigo-900 p-1">
      <ToggleButton isActive={i18n.language === 'en'} className="mr-1" onClick={(): void => setLang('en')}>
        English
      </ToggleButton>
      <ToggleButton isActive={i18n.language === 'fr'} onClick={(): void => setLang('fr')}>
        Français
      </ToggleButton>
    </div>
  );
};

export default LanguageToggle;
