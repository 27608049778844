import React from 'react';

type ToggleButtonProps = {
  isActive: boolean;
  className?: string;
  onClick?: () => void;
};

const commonClasses =
  'inline-flex items-center px-6 py-3 border border-transparent leading-6 font-medium rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150';
const activeClasses = 'bg-white text-base';
const inactiveClasses = 'bg-transparent text-white';

export const ToggleButton: React.FC<ToggleButtonProps> = ({ isActive: active, className, children, onClick }) => (
  <button
    onClick={onClick}
    type="button"
    className={`${className} ${commonClasses} ${active ? activeClasses : inactiveClasses}`}
  >
    {children}
  </button>
);

export default ToggleButton;
