import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import LOCALES from './locales';

const resources = {
  en: { translation: LOCALES.en },
  fr: { translation: LOCALES.fr },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: { checkWhitelist: true },
    whitelist: ['en', 'fr'],
    resources,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      transSupportBasicHtmlNodes: true,
    },
  });

export default i18n;
