import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import Banner from '../components/Banner';
import QuestionMultiple from '../components/QuestionMultiple';
import QuestionRadio from '../components/QuestionRadio';
import QuestionSelect from '../components/QuestionSelect';
import QuestionYesNo from '../components/QuestionYesNo';
import Data from '../data';
import { QuestionData } from '../data/models';

export type HandleNext = (
  evt: React.FormEvent<HTMLFormElement>,
  response: string | Set<string> | undefined,
  nextQid: string | undefined,
) => void;

const Questions: React.FC = () => {
  const [activeQid, setActiveQid] = useState(Data.questions[0]?.id);
  const [answers, setAnswers] = useState(new Set());
  const { i18n } = useTranslation();

  const handleNext: HandleNext = (evt, response, nextQid) => {
    evt.preventDefault();

    if (!response) {
      return;
    }

    const newAnswers = new Set(answers.values());
    const nextQidx = Data.questions.findIndex(q => q.id === nextQid);

    if (typeof response === 'string') {
      newAnswers.add(response);
    } else {
      for (const res of response) {
        newAnswers.add(res);
      }
    }

    setAnswers(newAnswers);
    setActiveQid(Data.questions[nextQidx]?.id);
  };

  const questionSwitch = (question: QuestionData): JSX.Element => {
    switch (question.type) {
      case 'select': {
        return <QuestionSelect question={question} handleNext={handleNext} />;
      }
      case 'multiple': {
        return <QuestionMultiple question={question} handleNext={handleNext} />;
      }
      case 'single': {
        return <QuestionRadio question={question} handleNext={handleNext} />;
      }
      case 'yesno': {
        return <QuestionYesNo question={question} handleNext={handleNext} />;
      }
      default: {
        return <p>Question type no supported</p>;
      }
    }
  };

  return (
    <div className="min-h-screen">
      <Banner />
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 min-h-full">
        <div className="min-w-screen mx-auto w-full max-w-md mx-auto py-16">
          {activeQid &&
            Data.questions.map(
              q =>
                q.id === activeQid &&
                q.question[i18n.language] && (
                  <div key={q.id} className="w-full">
                    {questionSwitch(q)}
                  </div>
                ),
            )}
        </div>
        {!activeQid && <Redirect to={`/results?p=${Array.from(answers).join(',')}`} />}
      </div>
    </div>
  );
};

export default Questions;
