import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { QuestionData } from '../data/models';
import { HandleNext } from '../pages/Questions';

interface Props {
  question: QuestionData;
  handleNext: HandleNext;
}

const QuestionRadio: React.FC<Props> = ({ question, handleNext }) => {
  const [t, i18n] = useTranslation();
  const [answer, setAnswer] = useState<string>();
  const [nextQid, setNextQid] = useState<string>();

  return (
    <form className="flex flex-col" onSubmit={(evt): void => handleNext(evt, answer, nextQid)}>
      <label className="label--main-q" id="questionRadioLabel" htmlFor="questionSelectCtrl">
        {question.question[i18n.language]}
      </label>
      {question.options
        .filter(opt => opt.label[i18n.language])
        .map(opt => (
          <div className="flex items-center mb-4" key={opt.id}>
            <input
              id={opt.id}
              name="form-input push_notifications"
              type="radio"
              checked={opt.id === answer}
              onChange={(): void => {
                setAnswer(opt.id);
                setNextQid(opt.next || question.next);
              }}
              className="form-radio h-6 w-6 text-blue-600 transition duration-150 ease-in-out"
            />
            <label htmlFor={opt.id} className="ml-3">
              <span className="block text-sm leading-5 font-medium text-gray-700">{opt.label[i18n.language]}</span>
            </label>
          </div>
        ))}

      <button type="submit" disabled={!answer} className="btn--next-q">
        {t('next_question')}
      </button>
    </form>
  );
};

export default QuestionRadio;
