import { useEffect } from 'react';
import ReactGA, { FieldsObject } from 'react-ga';
import { useLocation } from 'react-router-dom';

ReactGA.initialize('UA-1492428-12');

function useTracker(options?: FieldsObject): void {
  const { pathname, search } = useLocation();

  useEffect(() => {
    const trackPage = (page: string): void => {
      ReactGA.set({ page, ...options });
      ReactGA.pageview(page);
    };

    trackPage(pathname + search);
  }, [pathname, search, options]);
}

export default useTracker;
