import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Banner from '../components/Banner';
import ProgramItem from '../components/ProgramItem';
import Data from '../data';

const Results: React.FC = () => {
  const programs = new Set(new URLSearchParams(useLocation().search).get('p')?.split(','));
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-gray-100 pb-16">
      <Banner />
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 min-h-full">
        <div className="my-8">
          <h1 className="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10 ">
            {t('your_results_title')}
          </h1>
          <p className="mt-4 font-bold text-gray-600">{t('your_results_subtitle')}</p>
        </div>

        {Data.programs
          .filter(program => program.responses?.some(p => programs.has(p)))
          .map(program => (
            <ProgramItem key={program.id} program={program} isCard>
              {program.conditional_descriptions
                ?.filter(cd => cd.responses?.some(r => programs.has(r)))
                .map(conditional => (
                  <ProgramItem key={conditional.id} program={conditional} isCard={false} />
                ))}
            </ProgramItem>
          ))}
      </div>
    </div>
  );
};

export default Results;
