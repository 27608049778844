import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';

import { ProgramData } from '../data/models';

interface Props {
  program: ProgramData;
  isCard: boolean;
}

const ProgramItem: React.FC<Props> = ({ program, isCard, children }) => {
  const { i18n } = useTranslation();

  return (
    <section className={isCard ? `bg-white shadow sm:rounded-lg mb-8` : 'border-l-4 border-gray-300'}>
      <div className="px-4 py-5 sm:p-6">
        <h1 className="text-xl leading-7 font-semibold text-gray-900">{program.name?.[i18n.language]}</h1>
        <p className="mt-3 text-base leading-6 text-gray-500">{program.abstract?.[i18n.language]}</p>
        <p className="text-sm leading-5 text-gray-500 mt-3">{ReactHtmlParser(program.description?.[i18n.language])}</p>

        {children && <div className="mt-4">{children}</div>}

        {program.action && (
          <div className="mt-3">
            <a
              href={program.action.url[i18n.language]}
              target="_blank"
              rel="noopener noreferrer"
              className="text-base leading-6 font-semibold text-blue-600 hover:text-blue-500 transition ease-in-out duration-150 flex items-center"
            >
              {program.action.text[i18n.language]}
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-4 h-4 ml-1">
                <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
                <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
              </svg>
            </a>
          </div>
        )}
      </div>
    </section>
  );
};

export default ProgramItem;
