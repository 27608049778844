import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import logo from '../assets/riseup-logo-light-1.svg';
import LanguageToggle from '../components/LanguageToggle';

const IntroRoute: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="bg-riseup-blue overflow-hidden pt-12 pb-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 min-h-full">
          <div className="mx-auto max-w-screen-xl px-4 sm:px-6">
            <div className="text-center">
              <img src={logo} className="w-48 mx-auto mb-4" alt="RiseUP Strategies" />
              <h1 className="text-4xl tracking-tight leading-none font-extrabold text-white sm:text-5xl md:text-6xl uppercase">
                <Trans i18nKey="app_intro_title">
                  <span className="whitespace-no-wrap text-red-600" />
                </Trans>
              </h1>
              <div className="mt-5 max-w-md mx-auto flex justify-center md:mt-8">
                <LanguageToggle />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-2xl px-4 sm:px-6 lg:px-8 min-h-full py-16 m-auto text-center">
        <p className="mt-3 text-lg leading-7 text-gray-600">{t('intro_sub')}</p>
        <div className="mt-8">
          <div className="inline-flex rounded-md shadow">
            <Link to="/questions">
              <span className="bg-riseup-blue text-white inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md hover:text-gray-300 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                {t('get_started')}
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  className="ml-3 w-6 h-6"
                >
                  <path d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </span>
            </Link>
          </div>
        </div>
        <div className="text-left mt-16 pt-8 border-t border-gray-400">
          <h2 className="leading-10 font-bold text-gray-600 pb-4">{t('privacy_statement')}</h2>
          <p className="text-sm text-gray-600 leading-none pb-4">{t('privacy_statement_1')}</p>
          <p className="text-sm text-gray-600 leading-none pb-4">{t('privacy_statement_2')}</p>
          <p className="text-sm text-gray-600 leading-none pb-4">{t('privacy_statement_3')}</p>
          <p className="text-xs text-gray-600">
            {t('last_updated')}
            <time dateTime="2020-05-16"> 2020-05-16 </time>
          </p>
        </div>
        <div className="text-center text-sm mt-8 pt-8 border-t border-gray-400">
          <Trans i18nKey="contact">
            <a href={`mailto:${t('contact_email')}`}> </a>
          </Trans>
        </div>
      </div>
    </>
  );
};

export default IntroRoute;
