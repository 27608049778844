import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { QuestionData } from '../data/models';
import { HandleNext } from '../pages/Questions';

interface Props {
  question: QuestionData;
  handleNext: HandleNext;
}

const QuestionYesNo: React.FC<Props> = ({ question, handleNext }) => {
  const [t, i18n] = useTranslation();
  const [answers, setAnswers] = useState(new Set<string>());

  return (
    <form className="flex flex-col" onSubmit={(evt): void => handleNext(evt, answers, question.next)}>
      <label className="label--main-q mb-8" id="questionRadioLabel" htmlFor="questionSelectCtrl">
        {question.question[i18n.language]}
      </label>
      {question.options
        .filter(opt => opt.label[i18n.language])
        .map(opt => (
          <div key={opt.id} className="mb-8">
            <label className="block text-sm leading-5 font-medium text-gray-700 mb-6 " id="opt.id" htmlFor={opt.id}>
              {opt.label[i18n.language]}
            </label>

            <div aria-labelledby={opt.id} className="flex items-center content-around">
              <div className="w-1/2">
                <input
                  className="form-radio h-6 w-6 text-blue-600 transition duration-150 ease-in-out"
                  name={opt.id}
                  id={`${opt.id}_yes`}
                  type="radio"
                  checked={answers.has(`${opt.id}_yes`)}
                  onChange={(): void => {
                    const newAnswers = new Set(answers.values());
                    newAnswers.delete(`${opt.id}_no`);
                    newAnswers.add(`${opt.id}_yes`);
                    setAnswers(newAnswers);
                  }}
                />
                <label className="p-3" htmlFor={`${opt.id}_yes`}>
                  <span className="text-sm leading-5 font-medium text-gray-700">{t('yes')}</span>
                </label>
              </div>
              <div className="w-1/2">
                <input
                  className="form-radio h-6 w-6 text-blue-600 transition duration-150 ease-in-out"
                  name={opt.id}
                  id={`${opt.id}_no`}
                  type="radio"
                  checked={answers.has(`${opt.id}_no`)}
                  onChange={(): void => {
                    const newAnswers = new Set(answers.values());
                    newAnswers.delete(`${opt.id}_yes`);
                    newAnswers.add(`${opt.id}_no`);
                    setAnswers(newAnswers);
                  }}
                />
                <label className="p-3" htmlFor={`${opt.id}_no`}>
                  <span className="text-sm leading-5 font-medium text-gray-700">{t('no')}</span>
                </label>
              </div>
            </div>
          </div>
        ))}

      <button className="btn--next-q" type="submit" disabled={answers.size !== question.options.length}>
        {t('next_question')}
      </button>
    </form>
  );
};

export default QuestionYesNo;
