import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { QuestionData } from '../data/models';
import { HandleNext } from '../pages/Questions';

interface Props {
  question: QuestionData;
  handleNext: HandleNext;
}

const QuestionMultiple: React.FC<Props> = ({ question, handleNext }) => {
  const [t, i18n] = useTranslation();
  const [answers, setAnswers] = useState(new Set<string>());

  return (
    <form className="flex flex-col" onSubmit={(evt): void => handleNext(evt, answers, question.next)}>
      <Trans>
        <div className="label--main-q">{question.question[i18n.language]}</div>
      </Trans>
      {question.options
        .filter(opt => opt.label[i18n.language])
        .map(opt => (
          <div className="flex items-start mb-8" key={opt.id}>
            <div className="flex items-center h-5">
              <input
                id={opt.id}
                checked={answers.has(opt.id)}
                onChange={(): void => {
                  const newAnswers = new Set(answers.values());
                  if (newAnswers.has(opt.id)) {
                    newAnswers.delete(opt.id);
                  } else {
                    newAnswers.add(opt.id);
                  }
                  setAnswers(newAnswers);
                }}
                type="checkbox"
                className="form-checkbox h-6 w-6 text-blue-600 transition duration-150 ease-in-out"
              />
            </div>
            <div className="ml-3 text-sm leading-5">
              <label htmlFor={opt.id} className="font-medium text-gray-700">
                {opt.label[i18n.language]}
              </label>
            </div>
          </div>
        ))}
      <button type="submit" disabled={!question.optional && answers.size < 1} className="btn--next-q">
        {t('next_question')}
      </button>
    </form>
  );
};

export default QuestionMultiple;
