import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { QuestionData } from '../data/models';
import { HandleNext } from '../pages/Questions';

interface Props {
  question: QuestionData;
  handleNext: HandleNext;
}

const QuestionSelect: React.FC<Props> = ({ question, handleNext }) => {
  const [t, i18n] = useTranslation();
  const [answer, setAnswer] = useState<string>('select_one');

  return (
    <form className="flex flex-col" onSubmit={(evt): void => handleNext(evt, answer, question.next)}>
      <label className="label--main-q" id="questionSelectLabel" htmlFor="questionSelectCtrl">
        {question.question[i18n.language]}
      </label>
      <select
        className="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
        aria-labelledby="questionSelectLabel"
        name="question-select"
        id="questionSelectCtrl"
        value={answer}
        onChange={(evt): void => {
          setAnswer(evt.target.value);
        }}
      >
        <option value="select_one">{t('select_one')}</option>
        {question.options
          .filter(opt => opt.label[i18n.language])
          .map(opt => (
            <option key={opt.id} value={opt.id}>
              {opt.label[i18n.language]}
            </option>
          ))}
      </select>
      <button type="submit" disabled={answer === 'select_one'} className="btn--next-q">
        {t('next_question')}
      </button>
    </form>
  );
};

export default QuestionSelect;
